import React, { useEffect, useState } from 'react'
import { pdfjs } from "react-pdf";
import { ReactSortable } from 'react-sortablejs'
import { toastError } from '../components/common/Toast';
import { FiInfo } from 'react-icons/fi'
import { AiOutlineFileAdd } from 'react-icons/ai'
import { BsPlusSquare } from 'react-icons/bs'
import { IoIosCloseCircle } from 'react-icons/io'
import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import { fileAxios } from '../api/common';
GlobalWorkerOptions.workerSrc = window.location.origin + '/pdfjs/pdf.worker.min.js';


export default function MergePage() {
    // pdf 파일 정보
    const [pdfData, setPdfData] = useState([]);
    // 로딩중
    const [loading, setLoading] = useState(false)
    const [complete, setComplete] = useState(false)

    const allowDrop = (event) => {
        event.preventDefault();
    }

    useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;});


    const addFiles = async (event) => {
      const files = event.target.files;
      const pdfDataArray = [];
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // pdf 아닌 파일이 있으면 거르기
        if (file.type !== 'application/pdf') {
          toastError('PDF 파일만 선택해 주세요.')
          return
          }
        const reader = new FileReader();
  
        reader.onload = async (e) => {
          const typedArray = new Uint8Array(e.target.result);
  
          // Load PDF document using pdfjs
          const pdf = await pdfjs.getDocument({ data: typedArray }).promise;
          const pageInfo = await pdf.getPage(1);
          const numPages = pdf.numPages;
          
          // Render the first page to a canvas
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const viewport = pageInfo.getViewport({ scale: 1.0 });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
  
          await pageInfo.render(renderContext).promise;
          const imgDataUrl = canvas.toDataURL('image/png');
  
          // Push data to the array
          pdfDataArray.push({
            name: file.name,
            numPages: numPages,
            size: file.size,
            url: imgDataUrl,
            file: file
          });
  
          // Update state after all PDFs are processed
          if (pdfDataArray.length === files.length) {
            const new_files = [...pdfData]
            for(let i=0; i<pdfDataArray.length; i++){
              new_files.push(pdfDataArray[i])
            }
            setPdfData(new_files);
          }
        };
  
        reader.readAsArrayBuffer(file);
      }
    }

    const handleFileChange = async (event, drop) => {
      let files = null;
      
      if(drop){    
          event.preventDefault();
          files = event.dataTransfer.files;
      }else {
          files = event.target.files;
      }
  
      const pdfDataArray = [];
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // pdf 아닌 파일이 있으면 거르기
        if (file.type !== 'application/pdf') {
          toastError('PDF 파일만 선택해 주세요.')
          return
          }
        const reader = new FileReader();
  
        reader.onload = async (e) => {
          const typedArray = new Uint8Array(e.target.result);
  
          // Load PDF document using pdfjs
          const pdf = await pdfjs.getDocument({ data: typedArray }).promise;
          const pageInfo = await pdf.getPage(1);
          const numPages = pdf.numPages;
          
          // Render the first page to a canvas
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const viewport = pageInfo.getViewport({ scale: 1.0 });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
  
          await pageInfo.render(renderContext).promise;
          const imgDataUrl = canvas.toDataURL('image/png');
  
          // Push data to the array
          pdfDataArray.push({
            name: file.name,
            numPages: numPages,
            size: file.size,
            url: imgDataUrl,
            file: file
          });
  
          // Update state after all PDFs are processed
          if (pdfDataArray.length === files.length) {
            setPdfData(pdfDataArray);
          }
        };
  
        reader.readAsArrayBuffer(file);
      }
    };

    // 파일 삭제 기능
    const deleteFile = (i) => {
      const new_files = [...pdfData]
      new_files.splice(i, 1)
      setPdfData(new_files)
    }

    const mergePdf = () => {
      setLoading(true)
      setComplete(true)
      let formData = new FormData();
      for(let i = 0; i < pdfData.length; i++) {
        formData.append("pdf_files", pdfData[i].file);
      }
      fileAxios.post('pdfmerge/', formData, {
        responseType: 'blob' // 서버로부터 바이너리 데이터 형태로 응답을 받겠다고 설정합니다.
      })
      .then((res) => {
          setTimeout(() => {
            setLoading(false)
          }, 2000);
          // Blob 객체를 생성합니다.
          const blob = new Blob([res.data], { type: 'application/pdf' });
          // Blob 객체로부터 URL을 생성합니다.
          const downloadUrl = window.URL.createObjectURL(blob);
          // 생성된 URL을 이용하여 링크를 생성하고, 이 링크를 통해 파일을 다운로드합니다.
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "merged.pdf"; // 파일명을 설정합니다.
          document.body.appendChild(a);
          a.click(); // 링크를 클릭하여 파일을 다운로드합니다.
          // 다운로드 후 생성된 URL과 링크를 제거합니다.
          window.URL.revokeObjectURL(downloadUrl);
          a.remove();
      })
      .catch((err) => {
        console.log(err);
      });
    }

  return (
    <>
    {!complete ?
    <div className={`mx-10 md:mx-32 mt-12 flex flex-col ${pdfData.length !== 0 ? '' : 'items-center'}`}>
        <h1>PDF 병합하기</h1>
        <h3>파일을 추가하거나 드래그해서 편하게 PDF를 병합하세요.</h3>
        {pdfData.length === 0
        ?
        <div className='mt-6 w-full md:w-[80%] lg:w-[50%]'>
            <div
             className='flex justify-center items-center w-full h-[300px] bg-green-100 font-preM'
             onDrop={(e) => handleFileChange(e, true)} 
             onDragOver={allowDrop}
            >
              <BsPlusSquare className='mr-2' />
              PDF 파일을 여기에 두세요 !
            </div>
            <div className='mt-4 grid grid-cols-5 gap-4'>
                <label onChange={(e) => handleFileChange(e, false)} className='col-span-3 flex justify-center items-center h-[50px] rounded-sm bg-green-500 text-white cursor-pointer hover:bg-green-600'>
                    <input type="file" name="" id="" accept='.pdf' multiple style={{display: 'none'}} />
                    파일 여러개 선택하기
                </label>
                <div className='flex justify-center items-center h-[50px] rounded-sm bg-green-500 text-white cursor-pointer hover:bg-green-600'>임시1</div>
                <div className='flex justify-center items-center h-[50px] rounded-sm bg-green-500 text-white cursor-pointer hover:bg-green-600'>임시2</div>
            </div>
        </div>
        :
        <div className='mt-6 grid grid-cols-4 gap-4 w-full h-[480px]'>
          <div className='flex flex-col col-span-3 bg-green-100 py-4 pl-6 pr-2'>
            <div className='flex justify-end mr-4'>
              <label onChange={addFiles} className='group flex justify-center items-center h-[26px] rounded-full py-1 px-3 mr-4 bg-green-500 text-white cursor-pointer hover:bg-green-600'>
                  <input type="file" name="" id="" accept='.pdf' multiple style={{display: 'none'}} />
                  <AiOutlineFileAdd className='group-hover:hidden' />
                  <div className='hidden group-hover:flex'>파일 추가하기</div>
                </label>
            </div>
            <ReactSortable list={pdfData} setList={(newState)=>setPdfData(newState)} className='flex flex-wrap w-full h-[460px] overflow-y-scroll'>
            {pdfData.map((pdf, index) => {
                return (
                  <div key={index} className='flex flex-col items-end'>
                    <div className='group flex flex-col justify-center items-center w-[180px] h-[220px] overflow-y-hidden px-2 rounded-sm shadow-lg bg-white mr-4 cursor-move hover:border-2'>
                      <div className='mt-4 flex w-full justify-between text-[10px]'>
                        <div>{(pdf.size/1000000).toFixed(2)} MB</div>
                        <div className=''>{pdf.numPages} Page</div>
                      </div>
                      <img src={pdf.url} alt={`${pdf.name}`} width={100} height={150} className='mt-2 mb-3' />
                      <p className='text-[12px] text-center font-preR'>{pdf.name}</p>
                      <div className='absolute w-[180px] h-[220px] group-hover:bg-zinc-600/20'></div>
                      <div className='relative bottom-[194px] left-[66px] rounded-full cursor-pointer text-[26px] text-red-500 opacity-0 group-hover:opacity-100' onClick={()=>deleteFile(index)}><IoIosCloseCircle /></div>
                    </div>
                  </div>
                )
            })}
            </ReactSortable>
          </div>
          <div className='flex flex-col justify-between col-span-1 bg-zinc-200'>
            <div className='flex justify-center items-center font-preM mt-6'><FiInfo className='mr-2' />원하는 순서대로 배치해주세요</div>
            <div onClick={mergePdf} className='flex justify-center items-center text-[20px] font-preM w-full h-[100px] bg-green-500 hover:bg-green-600 cursor-pointer text-white'>변환하기</div>
          </div>
        </div>}
    </div>
    :
    <div className='flex w-full justify-center pt-40'>
      {loading ?
      <div className='flex flex-col items-center'>
        <img src={require('../assets/images/loading.gif')} alt='로딩중~~' />
        <div className='font-preB text-[22px] mt-10'>잠시만 기다려주세요</div>
      </div>
       :
       <div className='flex flex-col items-center'>
        <div className='font-preB text-[30px] mb-8'>변환이 완료되었습니다.</div>
        <div onClick={()=>{setComplete(false); setPdfData([])}} className='cursor-pointer bg-green-500 text-white rounded-md px-4 py-3 hover:bg-green-600'>추가 변환하러가기</div>
       </div> 
      }
    </div>
    }
    </>
  )
}
