import React from 'react'
import { Link } from 'react-router-dom'

export default function MainPage() {
  return (
    <>
    <div className='flex flex-col justify-center items-center h-[260px] bg-gradient-to-br from-[#EAE5C9] to-[#6CC6CB]'>
      <h1 className='font-preB mb-4'>PDF 편집이 어려운 당신을 위해</h1>
      <div className='text-center font-preM'>
        <h2><span className='text-[#25835C]'>무료</span> 사용 가능, 다양한 기능 제공</h2>
        <h2>PDF 파일 변환은 물론 병합과 분할, 용량을 줄여주기 위한 압축까지!</h2>
      </div>
    </div>
    <div className='grid sm:grid-cols-2 md:grid-cols-4 justify-items-center mt-10 text-[18px]'>
      <div>PDF 변환하기</div>
      <div>
        <div className='flex justify-center mb-4 pb-2 font-preSB border-b-4'>PDF 합치기</div>
        <Link to='/merge' className='flex items-center px-8 py-6 rounded-md hover:scale-[1.1] transition duration-500 ease-in-out hover:shadow-lg'>
          <img className='w-[50px]' src={require("../assets/images/pdf_icon.png")} alt="" />
          <span className='mx-2'>+</span>
          <img className='w-[50px]' src={require("../assets/images/pdf_icon.png")} alt="" />
        </Link>
      </div>
      <div>PDF 나누기</div>
      <div>PDF 압축</div>
    </div>
    </>
  )
}
