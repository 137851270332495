import axios from 'axios';

const baseURL = 'http://127.0.0.1:8080/'

// 로그인이 필요없는 axios
export const defaultAxios = axios.create({
  baseURL: `${baseURL}`,
});

// CORS Error ...?
// defaultAxios.defaults.withCredentials = true;

export const fileAxios = axios.create({
    baseURL: `${baseURL}`,
    headers: {
        "Content-Type" : "multipart/form-data"
    }
})