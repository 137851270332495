import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FaEarthAmericas } from 'react-icons/fa6'

export default function Navbar() {
  const [hover, setHover] = useState(false);
  return (
    <>
    <header>
      <div>
        <div className='flex justify-between items-center w-full h-[50px] bg-[#25835C] text-white px-10 text-[14px]'>
          <Link to='/'>로고자리</Link>
          <div
            onMouseEnter={()=>setHover(true)}
            onMouseLeave={()=>setHover(false)}
            className='flex w-[50%] justify-between cursor-pointer'
          >
            <div>PDF 변환하기</div>
            <div>PDF 합치기</div>
            <div>PDF 나누기</div>
            <div>PDF 압축하기</div>
          </div>
          <div className='grid grid-cols-3 gap-x-4 justify-items-center items-center text-center'>
            <div className='cursor-pointer font-preM'>로그인</div>
            <div className='p-2 bg-white text-[#25835C] rounded-md cursor-pointer font-preM'>회원가입</div>
            {/* 언어변경용 */}
            <div className='cursor-pointer font-preM'><FaEarthAmericas /></div> 
          </div>
        </div>
        <div className={`${hover ? '' : 'hidden'} w-full`}>
          <div className='fixed h-[200px] bg-[#25835C] w-full'>이얍</div>
        </div>
      </div>
    </header>
    </>
  )
}
