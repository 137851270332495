import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import Navbar from './components/common/Navbar';
import MergePage from './pages/MergePage';

export default function AppRouter() {
  return (
    <BrowserRouter>
        <Navbar />
        <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/merge' element={<MergePage />} />
        </Routes>
    </BrowserRouter>
  );
}