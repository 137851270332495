import { toast } from 'react-toastify'

export const toastError = (content) => {
    return (
        toast.error(content, {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            icon: "🚨",
            })
    )
}

export const toastOK = (content) => {
    return (
        toast.info(content, {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            icon: "✅",
            })
    )
}